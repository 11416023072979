import React, { useEffect, useState } from 'react';
import { Container, CircularProgress } from '@mui/material';
import { fetchEmployees, exportEmployee } from './exportApi';
import { fetchMappings } from '../glossar/skillApi';
import { useAuth } from 'react-oidc-context';
import { Employee, ContractorMapping } from '../../interfaces/rest';
import EmployeeExportTable from './employeeExportTable';

export default function ExportPage(): React.ReactElement {
    const [employees, setEmployees] = useState<Employee[]>([]);
    const [mappings, setMappings] = useState<ContractorMapping[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const auth = useAuth();

    useEffect(() => {
        const loadData = async () => {
            try {
                const [employeesData, mappingsData] = await Promise.all([
                    fetchEmployees(auth.user?.access_token || ''),
                    fetchMappings(auth.user?.access_token || ''),
                ]);
                setEmployees(employeesData);
                setMappings(mappingsData);
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        };
        loadData();
    }, [auth.user?.access_token]);

    const handleExport = async (employeeId: number, mappingName: string) => {
        try {
            await exportEmployee(
                auth.user?.access_token || '',
                employeeId,
                mappingName
            );
            alert('Employee exported successfully');
        } catch (error) {
            console.error(error);
            alert('Failed to export employee');
        }
    };

    if (loading) {
        return (
            <Container sx={{ textAlign: 'center', paddingTop: 4 }}>
                <CircularProgress />
            </Container>
        );
    }

    return (
        <Container sx={{ paddingTop: 4 }}>
            <EmployeeExportTable
                employees={employees}
                mappings={mappings}
                onExport={handleExport}
            />
        </Container>
    );
}
