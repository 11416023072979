import { Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import { FieldInputProps } from '../FieldInputProps';

export const Textfield: React.FC<FieldInputProps> = ({
    name,
    control,
    label,
    defaultValue,
    rules,
    ...props
}) => {
    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            rules={rules}
            render={({
                field: { onChange, value, ref },
                fieldState: { error },
            }) => (
                <TextField
                    helperText={error ? error.message : null}
                    size='small'
                    error={!!error}
                    onChange={onChange}
                    inputRef={ref}
                    placeholder='Hier bitte Wert eintragen'
                    value={value}
                    fullWidth
                    label={label}
                    variant='outlined'
                    {...props}
                />
            )}
        />
    );
};
