import { Controller, FormProvider, useForm } from 'react-hook-form';
import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Typography, Button, Stack, Container, Paper } from '@mui/material';
import { useAuth } from 'react-oidc-context';
import { useQuery } from '@tanstack/react-query';
import { jwtDecode } from 'jwt-decode';
import { Employee, Skill } from '../../interfaces/rest';
import { SkillsTable } from './skills/SkillsTable';
import { PersonalData } from './personalData/PersonalData';
import { Assignments } from './projects/Assignments';
import { fetchSkills, fetchEmployee } from './skillApi';

export default function EmployeeForm(): React.ReactElement {
    const initialEmployee: Employee = {
        id: 0,
        oidcUid: '',
        email: '',
        firstName: '',
        lastName: '',
        education: '',
        yearOfBirth: 0,
        nationality: '',
        languages: [],
        workExperience: '',
        keyAspects: '',
        qualification: '',
        assignments: [],
        itExperience: '',
        competencies: [],
        certifications: '',
        training: '',
    };

    const sanitizeEmployeeData = (employee: Employee) => ({
        ...initialEmployee,
        ...employee,
        id: employee.id ?? 0,
        oidcUid: employee.oidcUid ?? '',
        email: employee.email ?? '',
        firstName: employee.firstName ?? '',
        lastName: employee.lastName ?? '',
        education: employee.education ?? '',
        yearOfBirth: employee.yearOfBirth ?? 0,
        nationality: employee.nationality ?? '',
        languages: employee.languages ?? [],
        workExperience: employee.workExperience ?? '',
        keyAspects: employee.keyAspects ?? '',
        qualification: employee.qualification ?? '',
        assignments: employee.assignments ?? [],
        itExperience: employee.itExperience ?? '',
        competencies: employee.competencies ?? [],
        certifications: employee.certifications ?? '',
        training: employee.training ?? '',
    });

    const API_BASE_URL = import.meta.env.VITE_BACKEND_BASE_URL;
    const auth = useAuth();

    const [jwtId, setJwtId] = useState<string>('');
    const [employeeData, setEmployeeData] = useState<any>({
        employee: initialEmployee,
        languages: [],
        competencies: [],
        assignments: [],
        employeeFound: false,
    });
    const [tab, setTab] = useState<number>(1);
    const [skills, setSkills] = useState<Skill[]>([]);
    const [skillsTable, setSkillsTable] = useState<Skill[]>([]);

    const methods = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
    });
    const { handleSubmit, control, reset, formState } = methods;
    const { isValid } = formState;

    useEffect(() => {
        if (auth.user?.access_token) {
            const decoded = jwtDecode(auth.user.access_token) as any;
            const name = decoded.name as string;
            const id = decoded.oid as string;
            setJwtId(id);
            const [lastName, firstName] = name.split(', ');
            setEmployeeData((prev: { employee: any }) => ({
                ...prev,
                employee: {
                    ...prev.employee,
                    firstName,
                    lastName,
                    oidcUid: id,
                },
            }));
        }
    }, [auth.user?.access_token]);

    // fetchAllSkills function to get all existing skills
    const fetchAllSkills = useCallback(async () => {
        if (auth.user?.access_token) {
            const fetchedSkills = await fetchSkills(
                auth.user?.access_token ?? ''
            );
            setSkills(fetchedSkills);
        }
    }, [auth.user?.access_token]);

    useEffect(() => {
        fetchAllSkills();
    }, [fetchAllSkills]);

    // fetchEmployeeData function to load everything for an employee
    const fetchEmployeeData = async (
        jwtId: string,
        firstName: string,
        lastName: string
    ) => {
        if (!jwtId || !firstName || !lastName) {
            throw new Error('Missing required parameters');
        }

        let employeeResponse = await fetchEmployee(
            auth.user?.access_token || '',
            `findByOidcUid?projection=fullEmployee&oidcUid=${jwtId}`
        );

        if (!employeeResponse) {
            employeeResponse = await fetchEmployee(
                auth.user?.access_token || '',
                `findByFirstNameAndLastName?projection=fullEmployee&firstName=${firstName}&lastName=${lastName}`
            );
        }

        //when employee is found, fetch languages, competencies and assignments
        if (employeeResponse) {
            const languagesArray = (employeeResponse.languages || []).map(
                (language: { id: number; level: string; skill: Skill }) => ({
                    id: language.id,
                    level: language.level,
                    skillId: language.skill.id,
                    skill: language.skill,
                })
            );
            const competenciesArray = (employeeResponse.competencies || []).map(
                (competency: {
                    skill: { id: any };
                    category: any;
                    id: any;
                }) => ({
                    skill: competency.skill,
                    skillId: competency.skill.id,
                    category: competency.category,
                    id: competency.id,
                })
            );
            const assignmentsArray = (employeeResponse.assignments || []).map(
                (assignment: {
                    id: number;
                    mission: string;
                    role: string;
                    project: {
                        id: number;
                        name: string;
                        client: string;
                        sector: string;
                    };
                    tasks: string;
                    experiences: any[];
                    contacts: string;
                    period: {
                        entry: {
                            year: number;
                            month: number;
                        };
                        exit: {
                            year: number;
                            month: number;
                        };
                    };
                    teamSize: number;
                }) => ({
                    id: assignment.id,
                    mission: assignment.mission,
                    role: assignment.role,
                    project: {
                        id: assignment.project.id,
                        name: assignment.project.name,
                        client: assignment.project.client,
                        sector: assignment.project.sector,
                    },
                    tasks: assignment.tasks,
                    contacts: assignment.contacts,
                    period: {
                        entry: {
                            year: assignment.period.entry.year,
                            month: assignment.period.entry.month,
                        },
                        exit: {
                            ...(assignment.period.exit &&
                            assignment.period.exit !== null
                                ? {
                                      year: assignment.period.exit.year,
                                      month: assignment.period.exit.month,
                                  }
                                : {}),
                        },
                    },
                    teamSize: assignment.teamSize,
                    experiences: (assignment.experiences || []).map(
                        (experience: {
                            id: number;
                            level: string;
                            skill: Skill;
                        }) => ({
                            id: experience.id,
                            level: experience.level,
                            skillId: experience.skill.id,
                        })
                    ),
                })
            );

            console.log(assignmentsArray);

            //set collected Data
            setEmployeeData({
                employee: employeeResponse,
                languages: languagesArray,
                competencies: competenciesArray,
                assignments: assignmentsArray,
                employeeFound: true,
            });

            console.log(employeeData);
        }

        return employeeResponse;
    };

    const {
        isPending: isPendingGet,
        isError: isErrorGet,
        error: errorGet,
    } = useQuery({
        queryKey: [
            'employeeGet',
            jwtId,
            employeeData.employee.firstName,
            employeeData.employee.lastName,
        ],
        queryFn: () =>
            fetchEmployeeData(
                jwtId,
                employeeData.employee.firstName,
                employeeData.employee.lastName
            ),
        enabled:
            !!jwtId &&
            !!employeeData.employee.firstName &&
            !!employeeData.employee.lastName,
    });

    useEffect(() => {
        const sanitizedEmployee = {
            ...initialEmployee,
            ...sanitizeEmployeeData(employeeData.employee),
        };

        reset(sanitizedEmployee);
    }, [employeeData, reset]);

    const onSubmit = async (data: any) => {
        try {
            const languageData = (employeeData.languages || []).map(
                (language: { id: number; level: string; skill: Skill }) => {
                    return {
                        ...(language.id && language.id !== 0
                            ? { id: language.id }
                            : {}),
                        level: language.level,
                        skill: language.skill,
                    };
                }
            );

            const competenciesData = (employeeData.competencies || []).map(
                (competency: { skillId: number }) =>
                    `${API_BASE_URL}api/competencies/${competency.skillId}`
            );

            console.log(competenciesData);

            const assignmentsData = (employeeData.assignments || []).map(
                (assignment: {
                    mission: string;
                    role: string;
                    project: {
                        id: number;
                        name: string;
                        client: string;
                        sector: string;
                    };
                    tasks: string;
                    experiences: any[];
                    contacts: string;
                    period: {
                        entry: {
                            year: number;
                            month: number;
                        };
                        exit: {
                            year: number;
                            month: number;
                        };
                    };
                    teamSize: number;
                    id: number;
                }) => {
                    const mappedAssignment = {
                        mission: assignment.mission,
                        role: assignment.role,
                        project: {
                            name: assignment.project.name,
                            client: assignment.project.client,
                            sector: assignment.project.sector,
                            ...(assignment.project.id &&
                            assignment.project.id !== 0
                                ? { id: assignment.project.id }
                                : {}),
                        },
                        tasks: assignment.tasks,
                        experiences: assignment.experiences.map(
                            (experience: {
                                level: string;
                                skillId: number;
                            }) => ({
                                level: experience.level,
                                skill: `${API_BASE_URL}api/skills/${experience.skillId}`,
                            })
                        ),
                        contacts: assignment.contacts,
                        teamSize: assignment.teamSize,
                        ...(assignment.id && assignment.id !== 0
                            ? { id: assignment.id }
                            : {}),
                        ...(assignment.period.exit.year &&
                        assignment.period.exit.year !== 0 &&
                        assignment.period.exit.month &&
                        assignment.period.exit.month !== 0
                            ? {
                                  period: {
                                      entry: {
                                          year: assignment.period.entry.year,
                                          month: assignment.period.entry.month,
                                      },
                                      exit: {
                                          year: assignment.period.exit.year,
                                          month: assignment.period.exit.month,
                                      },
                                  },
                              }
                            : {
                                  period: {
                                      entry: {
                                          year: assignment.period.entry.year,
                                          month: assignment.period.entry.month,
                                      },
                                      exit: null,
                                  },
                              }),
                    };

                    return mappedAssignment;
                }
            );

            console.log(assignmentsData);

            data = {
                ...data,
                oidcUid: jwtId,
                languages: languageData,
                competencies: competenciesData,
                assignments: assignmentsData,
                email: `${employeeData.employee.firstName.toLowerCase()}.${employeeData.employee.lastName.toLowerCase()}@vc-g.de`,
            };

            console.log('data', data);

            const method = employeeData.employeeFound ? 'PATCH' : 'POST';
            const url = employeeData.employeeFound
                ? `${API_BASE_URL}api/employees/${employeeData.employee.id}`
                : `${API_BASE_URL}api/employees`;

            const response = await fetch(url, {
                method,
                body: JSON.stringify(data),
                headers: {
                    Authorization: `Bearer ${auth.user?.access_token}`,
                    'Content-Type': 'application/json',
                },
            });

            /* const responseCompetencies = await fetch(
                `${API_BASE_URL}api/employees/${employeeData.employee.id}/competencies`,
                {
                    method: 'PUT',
                    body: JSON.stringify(competenciesData),
                    headers: {
                        Authorization: `Bearer ${auth.user?.access_token}`,
                        'Content-Type': 'application/json',
                    },
                }
            );

            console.log(responseCompetencies);

            if (!responseCompetencies.ok) {
                throw new Error('Failed to add Competency');
            } */

            return await response.json();
        } catch (error) {
            console.error('Error saving employee data', error);
        }
    };

    // functions to switch tabs
    const handleNextTab = useCallback(() => {
        if (isValid) {
            setTab(prevTab => prevTab + 1);
        }
    }, [isValid]);

    const handlePreviousTab = useCallback(() => {
        setTab(prevTab => prevTab - 1);
    }, []);

    //populate the skills displayed in tab 2
    const skillArray = useMemo(() => {
        return [
            ...employeeData.languages.map(
                (language: { skill: Skill }) =>
                    skills.find(skill => skill.id === language.skill.id) || null
            ),
            ...employeeData.competencies.map(
                (competency: { skillId: number }) =>
                    skills.find(skill => skill.id === competency.skillId) ||
                    null
            ),
        ].filter(Boolean) as Skill[];
    }, [employeeData, skills]);

    useEffect(() => {
        setSkillsTable(skillArray);
    }, [skillArray]);

    // functions to add and delete skills for the skillTable in tab 2 and updating the employee
    const handleAddSkill = useCallback(
        (skill: Skill) => {
            setEmployeeData((prev: { languages: any; competencies: any }) => {
                if (skill.type === 'LANGUAGE') {
                    return {
                        ...prev,
                        languages: [
                            ...prev.languages,
                            { skillId: skill.id, level: '', skill: skill },
                        ],
                    };
                } else {
                    return {
                        ...prev,
                        competencies: [
                            ...prev.competencies,
                            { skillId: skill.id, category: '' },
                        ],
                    };
                }
            });

            setSkillsTable(prev => [...prev, skill]);
        },
        [setEmployeeData]
    );

    const handleDeleteSkill = useCallback(
        (skillIndex: number, skill: Skill) => {
            setEmployeeData(
                (prev: { languages: any[]; competencies: any[] }) => {
                    if (skill.type === 'LANGUAGE') {
                        return {
                            ...prev,
                            languages: prev.languages.filter(
                                lang => lang.skillId !== skill.id
                            ),
                        };
                    } else {
                        return {
                            ...prev,
                            competencies: prev.competencies.filter(
                                comp => comp.skillId !== skill.id
                            ),
                        };
                    }
                }
            );

            setSkillsTable(prev =>
                prev.filter((_, index) => index !== skillIndex)
            );
        },
        [setEmployeeData]
    );

    const handleUpdateLanguageLevel = useCallback(
        (skillId: number, newLevel: string) => {
            setEmployeeData((prev: { languages: any[] }) => ({
                ...prev,
                languages: prev.languages.map(language =>
                    language.skillId === skillId
                        ? { ...language, level: newLevel }
                        : language
                ),
            }));
        },
        [setEmployeeData]
    );

    // functions to handle projects in tab 3 and update employee
    const handleAddProject = (newAssignment: any) => {
        setEmployeeData((prevData: any) => ({
            ...prevData,
            assignments: [...(prevData.assignments || []), newAssignment],
        }));
    };

    const handleEditProject = (updatedAssignment: any, index: number) => {
        setEmployeeData((prevData: { assignments: any }) => {
            const updatedAssignments = [...prevData.assignments];
            updatedAssignments[index] = updatedAssignment;
            return {
                ...prevData,
                assignments: updatedAssignments,
            };
        });
    };

    const handleDeleteProject = (index: number) => {
        setEmployeeData((prevData: { assignments: any[] }) => ({
            ...prevData,
            assignments: prevData.assignments.filter((_, i) => i !== index),
        }));
    };

    if (isPendingGet) return <div>Loading employee data...</div>;
    if (isErrorGet) return <div>An error has occurred: {errorGet.message}</div>;

    return (
        <Container component='main' maxWidth='md'>
            <Paper elevation={3} sx={{ padding: 4, mt: 4 }}>
                <Typography
                    component='h1'
                    variant='h5'
                    align='center'
                    gutterBottom
                >
                    {tab === 1
                        ? 'Persönliche Daten'
                        : tab === 2
                          ? 'Skills'
                          : 'Projekte'}
                </Typography>

                <FormProvider {...methods}>
                    <form onSubmit={handleSubmit(onSubmit)} onReset={reset}>
                        {tab === 1 && (
                            <PersonalData
                                employee={employeeData.employee}
                                control={control}
                            />
                        )}

                        {tab === 2 && (
                            <SkillsTable
                                employeeData={employeeData}
                                handleDeleteSkill={handleDeleteSkill}
                                handleAddSkill={handleAddSkill}
                                handleUpdateLanguageLevel={
                                    handleUpdateLanguageLevel
                                }
                                skillsTable={skillsTable}
                                skills={skills}
                            />
                        )}

                        {tab === 3 && (
                            <Assignments
                                skills={skillsTable}
                                handleAddProject={handleAddProject}
                                handleEditProject={handleEditProject}
                                handleDeleteProject={handleDeleteProject}
                                assignments={employeeData.assignments}
                            />
                        )}
                        {tab === 3 && (
                            <Stack
                                spacing={2}
                                direction='row'
                                justifyContent='flex-end'
                                mt={2}
                            >
                                <Controller
                                    control={control}
                                    name='submit'
                                    render={({ field, formState }) => (
                                        <Button
                                            type='submit'
                                            size='medium'
                                            variant='contained'
                                            color='primary'
                                            disabled={formState.isSubmitting}
                                        >
                                            {formState.isSubmitting
                                                ? 'Saving...'
                                                : 'Save'}
                                        </Button>
                                    )}
                                />
                            </Stack>
                        )}

                        <Stack
                            spacing={2}
                            direction='row'
                            justifyContent='center'
                            mt={2}
                        >
                            {tab > 1 && (
                                <Button
                                    onClick={handlePreviousTab}
                                    variant='outlined'
                                    size='medium'
                                >
                                    Previous
                                </Button>
                            )}
                            {tab < 3 && (
                                <Button
                                    onClick={handleNextTab}
                                    variant='outlined'
                                    size='medium'
                                    disabled={!isValid}
                                >
                                    Next
                                </Button>
                            )}
                        </Stack>
                    </form>
                </FormProvider>
            </Paper>
        </Container>
    );
}
