import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import EmployeeForm from '../employee';
import Main from '../main';
import Glossar from '../glossar';
import Verwaltung from '../verwaltung';
import Header from '../header';
import Export from '../export';
import Search from '../search';

const element = [
    {
        path: '/',
        element: <Main />,
    },
    {
        path: '/home',
        element: <Main />,
    },
    {
        path: '/index.html',
        element: <Main />,
    },
];

export default function Profile(): React.ReactElement {
    return (
        <Router>
            <div className='Profile'>
                <Header />
                <Routes>
                    {/* Home Route  */}
                    {element.map(route => (
                        <Route
                            key={route.path}
                            path={route.path}
                            element={route.element}
                        />
                    ))}
                    {/* Profile Route  */}
                    <Route
                        key='/profile'
                        path='/profile'
                        element={<EmployeeForm />}
                    />
                    {/* Glossar */}
                    <Route
                        key='/glossar'
                        path='/glossar'
                        element={<Glossar />}
                    />
                    {/* Verwaltung */}
                    <Route
                        key='/verwaltung'
                        path='/verwaltung'
                        element={<Verwaltung />}
                    />
                    <Route path='/verwaltung' element={<Verwaltung />} />
                    {/* Export */}
                    <Route path='/export' element={<Export />} />
                    {/* Search */}
                    <Route path='/search' element={<Search />} />
                </Routes>
            </div>
        </Router>
    );
}
