import { Employee } from '../../interfaces/rest';

const API_BASE_URL = import.meta.env.VITE_BACKEND_BASE_URL;

const getAuthHeaders = (token: string) => ({
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
});

export const searchEmployees = async (
    token: string,
    query: string
): Promise<number> => {
    const response = await fetch(`${API_BASE_URL}api/search`, {
        method: 'POST',
        headers: getAuthHeaders(token),
        body: JSON.stringify({ query }),
    });

    if (!response.ok) {
        throw new Error('Failed to search employees');
    }

    const jsonResponse = await response.json();

    console.log(jsonResponse);
    return jsonResponse.id;
};

export const fetchSearchResult = async (
    token: string,
    searchId: number
): Promise<Employee[]> => {
    const aiSearchResponse = await fetch(
        `${API_BASE_URL}api/aiSearches/${searchId}?projection=fullAiSearch`,
        {
            method: 'GET',
            headers: getAuthHeaders(token),
        }
    );

    if (!aiSearchResponse.ok) {
        throw new Error('Failed to fetch aiSearch');
    }

    const aiSearchJson = await aiSearchResponse.json();
    console.log(aiSearchJson);
    const ratableResults = aiSearchJson.ratableResults;
    //TODO: instead of looping through employees, make one DB call
    //TODO: Return ratableResults to implement Rating functionality
    const employeeIds = ratableResults.map((result: any) => result.employeeId);
    const employees: Employee[] = [];
    for (const id of employeeIds) {
        const employee = await fetchEmployeeById(token, id);
        employees.push(employee);
    }

    return employees;
};

export const fetchEmployeeById = async (
    token: string,
    id: number
): Promise<Employee> => {
    const employeeResponse = await fetch(
        `${API_BASE_URL}api/employees/${id}?projection=fullEmployee`,
        {
            method: 'GET',
            headers: getAuthHeaders(token),
        }
    );

    if (!employeeResponse.ok) {
        throw new Error(`Failed to fetch employee with id ${id}`);
    }

    const employee = await employeeResponse.json();
    return employee;
};
