import { Employee } from '../../interfaces/rest';

const API_BASE_URL = import.meta.env.VITE_BACKEND_BASE_URL;

const getAuthHeaders = (token: string) => ({
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
});

export const fetchEmployees = async (token: string): Promise<Employee[]> => {
    const response = await fetch(`${API_BASE_URL}api/employees`, {
        method: 'GET',
        headers: getAuthHeaders(token),
    });

    if (!response.ok) {
        throw new Error('Failed to fetch employees');
    }

    const jsonResponse = await response.json();
    return jsonResponse._embedded.employees;
};

export const exportEmployee = async (
    token: string,
    employeeId: number,
    mapping: string
): Promise<void> => {
    const response = await fetch(
        `${API_BASE_URL}api/export?employeeId=${employeeId}&mapping=${encodeURIComponent(mapping)}`,
        {
            method: 'GET',
            headers: getAuthHeaders(token),
        }
    );

    if (!response.ok) {
        throw new Error('Failed to export employee');
    }

    const blob = await response.blob();
    console.log(response);
    console.log(response.headers);
    console.log(response.body);

    const contentDisposition = response.headers.get('Content-Disposition');
    let fileName = 'exported_employee.docx';

    console.log(contentDisposition);

    if (contentDisposition && contentDisposition.includes('filename=')) {
        fileName = contentDisposition.split('filename=')[1].trim();

        if (fileName.startsWith('"') && fileName.endsWith('"')) {
            fileName = fileName.substring(1, fileName.length - 1);
        }
    }

    const newBlob = new Blob([blob], {
        type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    });

    const url = window.URL.createObjectURL(newBlob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
};
